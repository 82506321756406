import { Component } from '@angular/core';
import { LacertaDynamicTypeComponent } from '@lacerta/cms';
import { VervitTwoColsBlock } from '@lacerta/vervit/feature-shell';

@Component({
  template: ` <vervit-two-columns [leftContent]="context?.left" [rightContent]="context?.right"></vervit-two-columns> `,
})
export class VervitWagtailTwoColumnsComponent extends LacertaDynamicTypeComponent<VervitTwoColsBlock> {
  context?: VervitTwoColsBlock;
}
