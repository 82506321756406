import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from './ui/ui.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule, UiModule],
  exports: [CommonModule, RouterModule, UiModule],
})
export class SharedModule {}
