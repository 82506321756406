import { Component, Input } from '@angular/core';

@Component({
  selector: 'vervit-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
})
export class ParagraphComponent {
  @Input() text?: string;
}
