import { Component, Input } from '@angular/core';

@Component({
  selector: 'vervit-two-columns',
  templateUrl: './two-columns.component.html',
})
export class TwoColumnsComponent {
  @Input() leftContent?: string;
  @Input() rightContent?: string;
}
