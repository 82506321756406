<section>
  <div class="container has-text-centered-mobile">
    <div class="level">
      <vervit-content-header [title]="product?.title"></vervit-content-header>
      <div class="level-right"><a class="has-text-dark-green" routerLink="../">< Back</a></div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <ng-container *ngIf="fullPage$ | async as fullPage">
          <img *ngIf="!fullPage.gallery_images.length" [src]="fullPage.thumbnail.url" />
          <div *ngIf="fullPage.gallery_images.length" class="columns is-multiline">
            <ng-container *ngFor="let image of galleryImages$ | async as galleryImages; let i = index">
              <div class="column is-narrow">
                <lacerta-gallery-image [images]="galleryImages" [imageIndex]="i"></lacerta-gallery-image>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="column is-two-thirds">
        <div class="content"><lacerta-rich-text-block [content]="(fullPage$ | async)?.body"></lacerta-rich-text-block></div>
      </div>
    </div>
  </div>
</section>
