<div class="hero">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-3">
          <div class="has-text-right-desktop has-text-centered-mobile">
            <lacerta-rich-text-block [content]="leftContent"></lacerta-rich-text-block>
          </div>
        </div>
        <div class="column is-7 has-vertically-aligned-content">
          <div class="content is-medium has-text-weight-bold has-text-centered-mobile">
            <lacerta-rich-text-block [content]="rightContent"></lacerta-rich-text-block>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
