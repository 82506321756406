<section>
  <div id="hero2" class="hero">
    <div class="hero-body">
      <div class="container"><div class="content" [innerHtml]="(currentDetailPage$ | async)?.intro"></div></div>
    </div>
    <div class="hero-head">
      <div class="container">
        <div class="columns">
          <div class="column is-4">
            <div class="has-text-centered-mobile has-text-dark-green">
              <div class="content is-medium"><div class="content" [innerHtml]="(currentDetailPage$ | async)?.address"></div></div>
            </div>
          </div>
          <div class="column is-6 has-vertically-aligned-content">
            <div class="content has-text-weight-bold has-text-dark-green is-medium">Contact us</div>
            <lacerta-form
              *ngIf="fields$ | async as fields"
              [fields]="fields"
              [target]="page?.meta?.slug"
              [formMessages]="formMessages"
            ></lacerta-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
