import { Component, Input } from '@angular/core';
import { VervitProductPage } from '../../../core/vervit-wagtail/product/product.model';

@Component({
  selector: 'vervit-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
})
export class ProductSummaryComponent {
  @Input() product?: VervitProductPage;
}
