import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VervitProductField, VervitProductPage } from './product.model';
import { PagesResponse, WagtailPagesApiService } from '@lacerta/wagtail';
import { vervitWagtailPageType } from '../page/page.model';

@Injectable()
export class ProductService {
  constructor(private pagesService: WagtailPagesApiService) {}

  getByParentId(parentId: number): Observable<VervitProductPage[]> {
    return this.pagesService
      .get<VervitProductPage>({
        type: vervitWagtailPageType.product,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        child_of: parentId,
        fields: [VervitProductField.thumbnail],
      })
      .pipe(map((pagesResponse: PagesResponse<VervitProductPage>) => pagesResponse.items));
  }
}
