import { NgModule } from '@angular/core';

import { VervitAppComponent } from './app.component';
import { VervitCoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { LacertaBulmaNavbarModule } from '@lacerta/bulma';

@NgModule({
  imports: [BrowserModule, VervitCoreModule, LacertaBulmaNavbarModule],
  declarations: [VervitAppComponent],
  bootstrap: [VervitAppComponent],
})
export class AppModule {}
