import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormPage } from '../../core/vervit-wagtail/contact-form/contact-form.model';
import { LacertaCmsNgrxFacade, LacertaWagtailPage } from '@lacerta/cms';
import { LacertaFormControlType, LacertaFormField, LacertaFormMessages, mapWagtailFormToLacertaForm } from '@lacerta/form';

@Component({
  selector: 'vervit-contact-page',
  templateUrl: './contact.component.html',
})
export class VervitContactComponent {
  @Input() page?: LacertaWagtailPage;
  currentDetailPage$: Observable<FormPage> = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(map((page) => page as FormPage));
  fields$: Observable<LacertaFormField<unknown>[]> = this.currentDetailPage$.pipe(
    map((page) =>
      mapWagtailFormToLacertaForm(page.form_fields)?.concat(
        {
          label: 'Are you human?',
          controlType: LacertaFormControlType.captcha,
          required: true,
          key: 'g-recaptcha-response',
        },
        {
          value: 'Send',
          controlType: LacertaFormControlType.submit,
          style: 'has-background-green-flashy has-text-white',
        }
      )
    )
  );
  formMessages: LacertaFormMessages = { errors: { required: 'This field is required.', email: 'Please enter a valid e-mail address.' } };

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
