import { Component } from '@angular/core';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { Observable } from 'rxjs';
import { VervitHome } from './core/vervit-wagtail/home/home.model';
import { map } from 'rxjs/operators';
import { LacertaBulmaNavbarBrand, LacertaBulmaNavbarItem } from '@lacerta/bulma';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';
import { filterNullOrUndefined } from '@lacerta/util';

@Component({
  selector: 'vervit-root',
  template: `
    <lacerta-bulma-navbar [navbarItems]="navbarItems$ | async" [brand]="brand$ | async"></lacerta-bulma-navbar>
    <router-outlet></router-outlet>
    <vervit-footer [logo]="(rootPage$ | async)?.logo_180"></vervit-footer>
  `,
})
export class VervitAppComponent {
  rootPage$ = this.lacertaCmsNgrxFacade.rootPage$.pipe(map((page) => page as VervitHome));

  brand$: Observable<LacertaBulmaNavbarBrand> = this.rootPage$.pipe(
    map((rootPage: VervitHome) => ({
      imageUrl: rootPage.logo_alt_180.url,
      width: 155,
    }))
  );
  navbarItems$: Observable<LacertaBulmaNavbarItem[]> = this.lacertaCmsNgrxFacade.menu$.pipe(
    filterNullOrUndefined(),
    map((menu) => this.mapToLacertaBulmaNavbarItem(menu))
  );

  private mapToLacertaBulmaNavbarItem = (menu: WagtailMenuPage[]): LacertaBulmaNavbarItem[] =>
    menu.map((item) => ({
      text: item.page.title,
      targetSlug: item.page.meta.slug,
      children: item.children ? this.mapToLacertaBulmaNavbarItem(item.children) : [],
      exact: item.exact ?? false,
    }));

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
