import { NgModule, Optional, SkipSelf } from '@angular/core';
import { VervitWagtailModule } from './vervit-wagtail/vervit-wagtail.module';
import { FooterComponent } from './footer/footer.component';
import { CommonModule } from '@angular/common';
import { UiModule } from '../shared/ui/ui.module';
import { RouterModule } from '@angular/router';
import { LacertaCoreModule } from '@lacerta/core';
import { LacertaWagtailComponentTypeMapping } from '@lacerta/cms';
import { LacertaMetaConfig, mapImageToMetaValues } from '@lacerta/meta';
import { VervitWagtailParagraphComponent } from './vervit-wagtail/block/paragraph.component';
import { VervitWagtailImageWithTextComponent } from './vervit-wagtail/block/image-with-text.component';
import { VervitWagtailTwoColumnsComponent } from './vervit-wagtail/block/two-columns.component';
import { VervitWagtailPageComponent } from './vervit-wagtail/page/vervit-page.component';
import { VervitWagtailProductTypePageComponent } from './vervit-wagtail/product/product-type.component';
import { VervitWagtailProductPageComponent } from './vervit-wagtail/product/product.component';
import { VervitWagtailContactPageComponent } from './vervit-wagtail/contact-form/contact-page.component';
import { vervitWagtailPageType } from './vervit-wagtail/page/page.model';
import { environment } from '../../environments/environment';
import { VervitProductPage } from './vervit-wagtail/product/product.model';
import { VervitWagtailBlockType } from './vervit-wagtail/block/block.model';
import { preventReimport } from '@lacerta/util';
import { BulmaFormControlComponent } from '@lacerta/bulma';

const wagtailComponentTypeMapping: LacertaWagtailComponentTypeMapping = [
  [VervitWagtailBlockType.paragraph, VervitWagtailParagraphComponent],
  [VervitWagtailBlockType.image_with_text, VervitWagtailImageWithTextComponent],
  [VervitWagtailBlockType.two_columns, VervitWagtailTwoColumnsComponent],
  [vervitWagtailPageType.page, VervitWagtailPageComponent],
  [vervitWagtailPageType.productIndex, VervitWagtailPageComponent],
  [vervitWagtailPageType.productType, VervitWagtailProductTypePageComponent],
  [vervitWagtailPageType.product, VervitWagtailProductPageComponent],
  [vervitWagtailPageType.form, VervitWagtailContactPageComponent],
];

export const vervitProductMetaMapping = (page: VervitProductPage) => ({
  ...(page.image && mapImageToMetaValues(page.image.meta.download_url, page.image.width, page.image.height)),
});

const metaConfig: LacertaMetaConfig<VervitProductPage> = {
  customPageMetaMappings: [[vervitWagtailPageType.product, vervitProductMetaMapping]],
};

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    VervitWagtailModule,
    LacertaCoreModule.forRoot({
      wagtailComponentTypeMapping,
      metaConfig,
      rootPageType: vervitWagtailPageType.root,
      menuParentPageType: vervitWagtailPageType.root,
      environment,
      formControlComponent: BulmaFormControlComponent,
    }),
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent, RouterModule],
})
export class VervitCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: VervitCoreModule) {
    preventReimport(parentModule, 'VervitCoreModule');
  }
}
