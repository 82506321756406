import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../shared/ui/ui.module';
import { VervitWagtailParagraphComponent } from './block/paragraph.component';
import { VervitWagtailImageWithTextComponent } from './block/image-with-text.component';
import { VervitWagtailTwoColumnsComponent } from './block/two-columns.component';
import { VervitWagtailPageComponent } from './page/vervit-page.component';
import { VervitWagtailProductTypePageComponent } from './product/product-type.component';
import { ProductModule } from '../../features/product/product.module';
import { VervitWagtailProductPageComponent } from './product/product.component';
import { VervitWagtailContactPageComponent } from './contact-form/contact-page.component';
import { ContactModule } from '../../features/contact/contact.module';
import { ProductService } from './product/product.service';
import { LacertaFullWidthImageModule } from '@lacerta/ui';
import { LacertaDynamicModule } from '@lacerta/cms';

const dynamicComponents = [
  VervitWagtailImageWithTextComponent,
  VervitWagtailParagraphComponent,
  VervitWagtailTwoColumnsComponent,
  VervitWagtailPageComponent,
  VervitWagtailProductTypePageComponent,
  VervitWagtailProductPageComponent,
  VervitWagtailContactPageComponent,
];

@NgModule({
  imports: [CommonModule, UiModule, ProductModule, ContactModule, LacertaFullWidthImageModule, LacertaDynamicModule],
  declarations: [dynamicComponents],
  providers: [ProductService],
})
export class VervitWagtailModule {}
