import { Component } from '@angular/core';
import { LacertaDynamicTypeComponent, LacertaImageChooserBlockImage } from '@lacerta/cms';
import { VervitImageWithTextBlock } from '@lacerta/vervit/feature-shell';
import { LacertaFullWidthImage } from '@lacerta/ui';

const mapImage = (imageChooserBlockImage: LacertaImageChooserBlockImage): LacertaFullWidthImage => ({
  url: imageChooserBlockImage.src,
  width: imageChooserBlockImage.width,
  height: imageChooserBlockImage.height,
});

@Component({
  template: `
    <lacerta-full-width-image [image]="image">
      <vervit-title-transparent-block *ngIf="context?.text">
        <h2 class="is-size-2-desktop is-size-3-touch is-size-4-mobile">
          <lacerta-rich-text-block [content]="context?.text"></lacerta-rich-text-block>
        </h2>
      </vervit-title-transparent-block>
    </lacerta-full-width-image>
  `,
})
export class VervitWagtailImageWithTextComponent extends LacertaDynamicTypeComponent<VervitImageWithTextBlock> {
  context?: VervitImageWithTextBlock;

  get image() {
    return this.context?.image?.original_image ? mapImage(this.context?.image?.original_image) : undefined;
  }
}
