import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleTransparentBlockComponent } from './title-transparent-block/title-transparent-block.component';
import { TwoColumnsComponent } from './two-columns/two-columns.component';
import { ParagraphComponent } from './paragraph/paragraph.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { RouterModule } from '@angular/router';
import { LacertaCmsModule } from '@lacerta/cms';

const uiComponents = [TitleTransparentBlockComponent, TwoColumnsComponent, ParagraphComponent, ContentHeaderComponent];

@NgModule({
  imports: [CommonModule, RouterModule, LacertaCmsModule],
  declarations: [uiComponents],
  exports: [uiComponents, LacertaCmsModule],
})
export class UiModule {}
