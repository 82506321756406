import { NgModule } from '@angular/core';
import { ProductTypeComponent } from './product-type/product-type.component';
import { VervitProductDetailComponent } from './product-detail/product-detail.component';
import { ProductSummaryComponent } from './product-summary/product-summary.component';
import { SharedModule } from '../../shared/shared.module';
import { LacertaGalleryImageModule } from '@lacerta/ui';

@NgModule({
  imports: [SharedModule, LacertaGalleryImageModule],
  declarations: [ProductTypeComponent, VervitProductDetailComponent, ProductSummaryComponent],
  exports: [ProductTypeComponent, VervitProductDetailComponent, ProductSummaryComponent],
})
export class ProductModule {}
