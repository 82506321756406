import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WagtailPage } from '@lacerta/wagtail';
import { VervitProductPage } from '../../../core/vervit-wagtail/product/product.model';
import { ProductService } from '../../../core/vervit-wagtail/product/product.service';

@Component({
  selector: 'vervit-product-type-page',
  templateUrl: './product-type.component.html',
})
export class ProductTypeComponent implements OnInit {
  @Input() productType?: WagtailPage;

  products$?: Observable<VervitProductPage[]>;

  constructor(private productService: ProductService) {}

  ngOnInit() {
    if (this.productType?.id) {
      this.products$ = this.productService.getByParentId(this.productType?.id);
    }
  }
}
