<div class="item">
  <div class="columns has-text-centered-mobile">
    <div class="column is-one-third">
      <a routerLink="{{ product?.meta?.slug }}"><img [src]="product?.thumbnail?.url" /></a>
    </div>
    <div class="column is-two-third has-text-dark-green">
      <div>{{ product?.title }}</div>
      <br />
      <div><a routerLink="{{ product?.meta?.slug }}" class="has-text-dark-green">View product details ></a></div>
    </div>
  </div>
</div>
