import { SharedModule } from '../../shared/shared.module';
import { VervitContactComponent } from './contact.component';
import { LacertaBulmaModule } from '@lacerta/bulma';
import { NgModule } from '@angular/core';
import { LacertaFormModule } from '@lacerta/form';

@NgModule({
  imports: [SharedModule, LacertaFormModule, LacertaBulmaModule],
  declarations: [VervitContactComponent],
  exports: [VervitContactComponent],
})
export class ContactModule {}
