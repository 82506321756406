import { Component, Input } from '@angular/core';
import { ProductGalleryImage, VervitProductPage } from '../../../core/vervit-wagtail/product/product.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LacertaGalleryImage } from '@lacerta/ui';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';

@Component({
  selector: 'vervit-product-detail-page',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class VervitProductDetailComponent {
  @Input() product?: VervitProductPage;

  fullPage$: Observable<VervitProductPage> = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(map((page) => page as VervitProductPage));
  galleryImages$: Observable<LacertaGalleryImage[]> = this.fullPage$.pipe(
    map((page) =>
      page.gallery_images.map((productGalleryImage: ProductGalleryImage) => ({
        original: productGalleryImage.image.meta.download_url,
        thumbnail: productGalleryImage.thumbnail.url,
      }))
    )
  );

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
