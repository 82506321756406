/* eslint-disable @typescript-eslint/naming-convention */
import { WagtailImage, WagtailImageRenditionField } from '@lacerta/wagtail';
import { LacertaWagtailPage } from '@lacerta/cms';

export interface ProductGalleryImage {
  id: number;
  meta: {
    type: string;
  };
  image: WagtailImage;
  caption: string;
  thumbnail: WagtailImageRenditionField;
}

export interface VervitProductPage extends LacertaWagtailPage {
  image: WagtailImage;
  thumbnail: WagtailImageRenditionField;
  gallery_images: ProductGalleryImage[];
  body: string;
}

export enum VervitProductField {
  thumbnail = 'thumbnail',
  gallery_images = 'gallery_images',
}
