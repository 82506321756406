import { Component, Input } from '@angular/core';
import { WagtailImageRenditionField } from '@lacerta/wagtail';

@Component({
  selector: 'vervit-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  @Input() logo?: WagtailImageRenditionField;
}
